import React from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const CustomTextField = ({
  id,
  type = "text",
  label,
  value,
  handleChange,
  handleblur,
  customHelperText = "",
  errors,
  touched,
  required = true,
  disabled = false,
  customRef,
  style = {},
  otherProps,
}) => {
  const [showPassword, setShowPassword] = React.useState();

  return (
    <TextField
      id={id}
      variant="outlined"
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      value={value}
      label={label}
      error={errors && touched ? true : false}
      helperText={errors && touched ? errors : customHelperText}
      onChange={handleChange}
      onBlur={handleblur}
      required={required}
      inputProps={{ ref: customRef }}
      disabled={disabled}
      InputLabelProps={{ sx: { fontSize: "1.6rem" } }}
      InputProps={{
        ...(type === "password" && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }),
        sx: { height: "5rem", fontSize: "1.6rem" },
      }}
      {...otherProps}
      sx={{
        width: "100% !important",
        fontFamily: "Montserrat !important",
        backfaceVisibility: "hidden",
        ...style,
      }}
    />
  );
};

export default React.memo(CustomTextField);
