import AgoraRTC from "agora-rtc-sdk-ng";

export const createAndPublishStream = async ({ agoraEngine, alert, cb }) => {
  try {
    if (agoraEngine) {
      const localTracks = await AgoraRTC.createMicrophoneAndCameraTracks(
        // Audio config options
        {
          microphoneId: "default",
          microphoneVolume: 50,
          microphoneMuted: false,
          microphoneSampleRate: 44100,
          microphoneChannelCount: 1,
          microphoneEchoCancellation: true,
          microphoneAutoGainControl: true,
          microphoneNoiseSuppression: true,
          microphoneHighpassFilter: true,
        },
        // Video config options
        {
          encoderConfig: {
            width: { min: 640, ideal: 1920, max: 1920 },
            height: { min: 480, ideal: 1080, max: 1080 },
          },
        }
      );

      // localTracks is an array => [audioStream, videoStream]
      await agoraEngine.publish(localTracks);

      // Pass localTracks to callback and perform any necessary actions
      cb(localTracks);

      return localTracks;
    } else {
      throw new Error("Agora engine not initialized");
    }
  } catch (err) {
    console.log(`Error while creating and publishing stream: ${err.message}`);
    alert("Failed to start video and audio stream", "warning");
  }
};
