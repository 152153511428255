import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";

import CustomButtonAdv from "../Reuseable/CustomButtonAdv";
import CustomTextField from "../Reuseable/CustomTextField";
import Logo from "../Reuseable/Logo";
import { useAgoraContext } from "../../context/AgoraContext";
import { makeRequest } from "../../utilities/fetcher";
import { GET_AGORA_AUTH_TOKENS } from "../../constants/urls";
import { useToasterContext } from "../../context/ToasterContext";
import { createAgoraRTMInstance } from "../../lib/createAgoraRTMInstance";
import { createAgoraRTCInstance } from "../../lib/createAgoraRTCInstance";
import { toUpperCaseFirstLetter } from "utilities/toUpperCaseFirstLetter";

const RenderHome = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const alert = useToasterContext();

  const type = searchParams.get("type");
  const appt = searchParams.get("appt");
  const user = searchParams.get("user");
  const name = searchParams.get("name");
  const disabled = searchParams.get("d") === "1";

  const { remoteVideoRef } = useAgoraContext();
  const { setAgoraEngine, setAgoraRTMInstance, setAgoraRTMChannel, setMembers, setLocalPeerDetails, setRemotePeerDetails } = useAgoraContext();

  const formik = useFormik({
    initialValues: { uid: "", displayName: "", userType: "", channelName: "" },
    validationSchema: Yup.object().shape({
      uid: Yup.string().required("UID is required"),
      displayName: Yup.string().required("Display name is required"),
      userType: Yup.string().required("User type is required"),
      channelName: Yup.string().required("Channel name is required"),
    }),
    onSubmit: async (values) => {
      const { uid, displayName, userType, channelName: roomId } = values;

      try {
        if (!uid) return alert("UID must be provided", "warning");
        if (!roomId) return alert("Channel name must be provided", "warning");

        const data = await makeRequest({
          alert,
          method: "POST",
          show_success_message: false,
          show_fallback: false,
          pathname: GET_AGORA_AUTH_TOKENS,
          values: { uid, model: userType, roomId },
        });

        if (data?.code === 200 && data?.payload?.RTMAuthToken && data?.payload?.RTCAuthToken) {
          const { status, message, members, channel, rtmClient } = await createAgoraRTMInstance({
            uid,
            roomId,
            userType,
            displayName,
            setMembers,
            setRemotePeerDetails,
            token: data.payload.RTMAuthToken,
            getDeviceStatusCB: () => useAgoraContext,
          });

          if (status === "error") return alert(message, "warning");

          const agoraEngine = await createAgoraRTCInstance({
            uid,
            roomId,
            remoteVideoRef,
            token: data.payload.RTCAuthToken,
          });

          setMembers(members);
          setAgoraEngine(agoraEngine);
          setAgoraRTMChannel(channel);
          setAgoraRTMInstance(rtmClient);
          setLocalPeerDetails({ displayName, userType, uid, roomId });

          navigate(`/room/${roomId}`, { state: { displayName, userType, uid, roomId } });
        }
      } catch (err) {
        alert(err.message, "warning");
      }
    },
  });

  React.useEffect(() => {
    formik.resetForm({
      values: {
        uid: user ?? "",
        displayName: name ?? "",
        channelName: appt ?? "",
        userType: toUpperCaseFirstLetter(type) ?? "",
      },
    });
  }, [type, appt, user, name]);

  return (
    <Box>
      <Logo rootStyles={{ margin: "auto", height: "15rem", width: "15rem" }} />
      <Box
        component="form"
        sx={{
          display: "grid",
          gap: "1.25rem",
          padding: "2rem",
          width: "50rem",
          margin: "auto",
          boxShadow: 2,
          borderRadius: "2rem",
          placeItems: "center",
          backgroundColor: "custom.white",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        }}
      >
        <CustomTextField
          id="uid"
          label="UID"
          disabled={disabled || formik.isSubmitting}
          value={formik.values.uid}
          handleChange={formik.handleChange}
          handleblur={formik.handleBlur}
          errors={formik.errors.uid}
          touched={formik.touched.uid}
        />

        <CustomTextField
          id="displayName"
          label="Display Name"
          value={formik.values.displayName}
          disabled={disabled || formik.isSubmitting}
          handleChange={formik.handleChange}
          handleblur={formik.handleBlur}
          errors={formik.errors.displayName}
          touched={formik.touched.displayName}
        />

        <CustomTextField
          id="userType"
          label="User Type"
          value={formik.values.userType}
          disabled={disabled || formik.isSubmitting}
          handleChange={formik.handleChange}
          handleblur={formik.handleBlur}
          errors={formik.errors.userType}
          touched={formik.touched.userType}
        />

        <CustomTextField
          id="channelName"
          label="Channel Name"
          value={formik.values.channelName}
          disabled={disabled || formik.isSubmitting}
          handleChange={formik.handleChange}
          handleblur={formik.handleBlur}
          errors={formik.errors.channelName}
          touched={formik.touched.channelName}
        />

        <CustomButtonAdv
          type="submit"
          loading={formik.isSubmitting}
          IconDirection="left"
          text="Start Consultation"
          Icon={VideocamIcon}
          rootStyles={{ fontSize: "14px" }}
          buttonStyles={{ height: "42px", padding: "0 1.25rem" }}
          fn={formik.handleSubmit}
        />
      </Box>
    </Box>
  );
};

export default RenderHome;
