import React from "react";

const AgoraContext = React.createContext(null);
export const useAgoraContext = () => React.useContext(AgoraContext);

const AgoraContextProvider = ({ children }) => {
  const localVideoRef = React.useRef(null);
  const remoteVideoRef = React.useRef(null);

  const [members, setMembers] = React.useState([]);
  const [localPeerDetails, setLocalPeerDetails] = React.useState({});
  const [remotePeerDetails, setRemotePeerDetails] = React.useState({});

  const [agoraEngine, setAgoraEngine] = React.useState(null);
  const [agoraRTMInstance, setAgoraRTMInstance] = React.useState(null);
  const [agoraRTMChannel, setAgoraRTMChannel] = React.useState(null);

  const [localTracks, setLocalTracks] = React.useState(null);

  return (
    <AgoraContext.Provider
      value={{
        remoteVideoRef,
        localVideoRef,
        localTracks,
        setLocalTracks,
        agoraEngine,
        setAgoraEngine,
        agoraRTMInstance,
        setAgoraRTMInstance,
        agoraRTMChannel,
        setAgoraRTMChannel,
        members,
        setMembers,
        localPeerDetails,
        setLocalPeerDetails,
        remotePeerDetails,
        setRemotePeerDetails,
      }}
    >
      {children}
    </AgoraContext.Provider>
  );
};

export default AgoraContextProvider;
