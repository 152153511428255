import React from 'react';

export default function useLoader() {
  const [isLoading, setIsLoading] = React.useState(false);

  const setLoadingTrue = () => setIsLoading(true);
  const setLoadingFalse = () => setIsLoading(false);

  return { isLoading, setLoadingTrue, setLoadingFalse };
}
