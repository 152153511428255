import { Box, IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

import Logo from "../Logo";

const VideoPlayer = ({
  videoRef,
  videoTrack,
  showDeviceStatus = false,
  displayName,
  placeHolderImage,
  placeHolderText,
  remotePeerMicMuted,
  remotePeerVideoMuted,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "#A0A0A0",
        boxShadow: 2,
        borderRadius: ".5rem",
        overflow: "hidden",
        position: "relative",
        border: "2px solid white",
      }}
    >
      <Box
        ref={videoRef}
        sx={{
          position: "relative",
          zIndex: 2,
          height: "100%",
          width: "100%",
          display: videoTrack?.muted === true ? "none" : "block",
        }}
      />

      {displayName && (
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            zIndex: 3,
            typography: "subtitle1",
            backgroundColor: "rgba(0, 0, 0, .5)",
            color: "white",
            padding: ".25rem",
          }}
        >
          {displayName}
        </Box>
      )}

      {showDeviceStatus && (
        <Box sx={{ position: "absolute", top: "1rem", right: "1rem", zIndex: 3 }}>
          <IconButton sx={{ backgroundColor: "rgba(0, 0, 0, .5)", pointerEvents: "none" }}>
            {remotePeerMicMuted ? <MicOffIcon sx={{ color: "white" }} /> : <MicIcon sx={{ color: "white" }} />}
          </IconButton>

          <IconButton sx={{ backgroundColor: "rgba(0, 0, 0, .5)", marginLeft: ".5rem", pointerEvents: "none" }}>
            {remotePeerVideoMuted ? <VideocamOffIcon sx={{ color: "white" }} /> : <VideocamIcon sx={{ color: "white" }} />}
          </IconButton>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            width: "max-content",
            margin: "auto",
            borderRadius: "50%",
            padding: "2rem",
            boxShadow: 2,
            pointerEvents: "none",
          }}
        >
          <Logo src={placeHolderImage} />
        </Box>

        {videoTrack?.muted === true ? null : <Box sx={{ textAlign: "center", typography: "body1", marginTop: "1rem", color: "white" }}>{placeHolderText}</Box>}
      </Box>
    </Box>
  );
};

export default VideoPlayer;
