import AgoraRTM from "agora-rtm-sdk";
import { getItemFromLocalStorage } from "utilities/getItemFromLocalStorage";

export const createAgoraRTMInstance = async ({ token, roomId, uid, userType = "", displayName = "", setMembers, setRemotePeerDetails, getDeviceStatusCB }) => {
  try {
    const rtmClient = AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID);

    await rtmClient.login({ token, uid });

    rtmClient.on("MessageFromPeer", (message, peerId) => {
      const { userType, displayName, isMicMuted, isVideoMuted } = JSON.parse(message.text);

      setRemotePeerDetails((details) => ({
        ...details,
        [peerId]: {
          userType: userType ?? details[peerId]?.userType ?? "",
          displayName: displayName ?? details[peerId]?.displayName ?? "",
          isMicMuted: isMicMuted ?? details[peerId]?.isMicMuted ?? false,
          isVideoMuted: isVideoMuted ?? details[peerId]?.isVideoMuted ?? false,
        },
      }));
    });

    let channel = rtmClient.createChannel(roomId);
    await channel.join();

    /* Max no. of members in a room is 2 */
    const members = await channel.getMembers();

    if (members.length > process.env.REACT_APP_MAX_NO_OF_MEMBERS_IN_ROOM) {
      await channel.leave();
      await rtmClient.logout();

      return { status: "error", message: "Room limit reached" };
    }

    /* Store member IDs */
    setMembers(members);

    /* Send my display name and usertype through CHANNEL MESSAGE over to all the other peers when I join */
    channel
      .sendMessage({
        text: JSON.stringify({ displayName, userType }),
        messageType: AgoraRTM.MessageType.TEXT,
      })
      .then(() => console.log("Message sent successfully"))
      .catch((err) => console.log("Error sending message", err.message));

    channel.on("ChannelMessage", (message, memberId) => {
      const { userType, displayName, isMicMuted, isVideoMuted } = JSON.parse(message.text);

      setRemotePeerDetails((details) => ({
        ...details,
        [memberId]: {
          userType: userType ?? details[memberId]?.userType ?? "",
          displayName: displayName ?? details[memberId]?.displayName ?? "",
          isMicMuted: isMicMuted ?? details[memberId]?.isMicMuted ?? false,
          isVideoMuted: isVideoMuted ?? details[memberId]?.isVideoMuted ?? false,
        },
      }));
    });

    channel.on("MemberJoined", async (memberId) => {
      /* Store member IDs again when a new member joins */
      const members = await channel.getMembers();
      setMembers(members);

      /* Send my display name ,usertype, mic and video status through PEER TO PEER MESSAGE over to other peer when they join */
      const isMicMuted = getItemFromLocalStorage("isMyMicMuted") === "true" ? true : false;
      const isVideoMuted = getItemFromLocalStorage("isMyVideoMuted") === "true" ? true : false;

      rtmClient
        .sendMessageToPeer({ text: JSON.stringify({ displayName, userType, isMicMuted, isVideoMuted }) }, memberId)
        .then((sendResult) => {
          if (sendResult.hasPeerReceived) {
            // Your code for handling the event when the remote user receives the message.
          } else {
            // Your code for handling the event when the message is received
            // by the server but the remote user cannot be reached.
          }
        })
        .catch((err) => console.log("Error sending message", err.message));
    });

    channel.on("MemberLeft", (memberId) => {
      setRemotePeerDetails((details) => ({ ...details, [memberId]: {} }));
      setMembers((members) => members.filter((member) => member !== memberId));
    });

    console.warn("RTM Instance connected successfully");

    return { channel, rtmClient, members };
  } catch (err) {
    return `Error while creating AGORA RTM Instance: ${err.message}`;
  }
};
