import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import { ThemeProvider } from "@mui/material";

import App from "./App";
import AgoraContextProvider from "./context/AgoraContext";
import ToasterProvider from "./context/ToasterContext";
import theme from "./utilities/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ToasterProvider>
        <AgoraContextProvider>
          <App />
        </AgoraContextProvider>
      </ToasterProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
