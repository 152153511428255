import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const GetIcon = ({ Icon, style }) => {
  return (
    <>
      {Icon && typeof Icon === "object" && <Icon sx={{ fontSize: "25px", marginLeft: "1rem", ...style }} />}
      {Icon && typeof Icon === "function" && Icon()}
    </>
  );
};

const CustomButtonAdv = ({
  text,
  href,
  hrefExt,
  isActive = false,
  variant = "contained",
  color = "primary",
  type = "button",
  Icon,
  enableIcon = true,
  IconDirection = "right",
  disabled,
  loading = false,
  fn,
  rootStyles,
  buttonStyles,
  customRef,
  ...otherProps
}) => {
  const ButtonContent = () => (
    <Box
      sx={{
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: "0 1.5rem",
        minWidth: "12rem",
        ...buttonStyles,
      }}
    >
      {IconDirection === "right" && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {text}{" "}
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            {enableIcon ? (
              Icon ? (
                <GetIcon Icon={Icon} />
              ) : (
                <NavigateNextIcon sx={{ fontSize: "25px", marginLeft: "1rem" }} />
              )
            ) : null}
          </Box>
        </Box>
      )}

      {IconDirection === "left" && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {enableIcon ? (
            Icon ? (
              <GetIcon Icon={Icon} style={{ marginLeft: 0, marginRight: "1rem" }} />
            ) : (
              <NavigateNextIcon sx={{ fontSize: "25px", marginRight: "1rem" }} />
            )
          ) : null}{" "}
          {text}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Button
        variant={variant}
        color={color}
        type={type}
        onClick={(e) => {
          if (fn) fn(e);
          else return null;
        }}
        disabled={loading || disabled}
        sx={{
          borderRadius: "3rem",
          fontSize: "16px",
          fontFamily: "Montserrat",
          position: "relative",
          width: "max-content",
          padding: 0,
          height: "max-content",
          textTransform: "none",
          outline: "none",
          overflow: "hidden",
          ...(isActive && { border: "none" }),
          ...(isActive && { backgroundColor: "#F7F7F7" }),

          "& a": {
            color: variant === "outlined" || variant === "text" ? "custom.blue_1" : "custom.white",
            textDecoration: "none",
            borderRadius: "3rem",
            ...(isActive && { border: "none" }),
            ...(isActive && { backgroundColor: "#F7F7F7" }),
          },

          ...rootStyles,
        }}
        {...otherProps}
      >
        {!loading ? (
          href ? (
            <Link to={href}>
              <ButtonContent />
            </Link>
          ) : hrefExt ? (
            <a href={hrefExt} target="_blank" rel="noreferrer" style={{ width: "100%" }}>
              <ButtonContent />
            </a>
          ) : (
            <ButtonContent />
          )
        ) : (
          <Box
            sx={{
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "0 1.5rem",
              minWidth: "12rem",
              ...buttonStyles,
            }}
          >
            <CircularProgress color="primary" sx={{ height: "30px", width: "30px", margin: "auto" }} />
          </Box>
        )}
      </Button>
    </>
  );
};

export default CustomButtonAdv;
