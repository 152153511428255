import React from "react";

import { Box } from "@mui/material";

const Layout = ({ children, rootStyles = {} }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        padding: "2rem",
        background: "linear-gradient(-45deg, #5e7fb1, #dce8f7, #eff1f4, #fce1a8, #f7ec86)",
        ...rootStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
