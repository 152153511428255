import React from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = ({ handleClick }) => {
  return (
    <IconButton
      sx={{
        position: "absolute",
        right: "10px",
        top: "10px",
        zIndex: 100,

        "&:hover": { color: "red" },
      }}
      onClick={handleClick}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
