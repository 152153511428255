import AgoraRTC from "agora-rtc-sdk-ng";

export const createAgoraRTCInstance = async ({ roomId, token, uid, remoteVideoRef }) => {
  try {
    const agoraEngine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    await agoraEngine.join(process.env.REACT_APP_AGORA_APP_ID, roomId, token, uid);

    agoraEngine.on("user-published", async (user, mediaType) => {
      await agoraEngine.subscribe(user, mediaType);

      if (mediaType === "video" && remoteVideoRef.current) {
        // Play the remote video track.
        user.videoTrack.play(remoteVideoRef.current);
      }

      if (mediaType === "audio" && remoteVideoRef.current) {
        // Play the remote audio track.
        // No need to pass element ref.
        user.audioTrack.play();
      }
    });

    agoraEngine.on("user-left", (data) => {});

    console.warn("RTC Instance connected successfully");

    return agoraEngine;
  } catch (err) {
    return `Error while creating AGORA RTC Instance: ${err.message}`;
  }
};
