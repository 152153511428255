import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Room from "./pages/Room";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/room/:roomId" element={<Room />} exact />
    </Routes>
  );
}

export default App;
