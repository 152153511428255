import Layout from "../components/Reuseable/Layout";
import RenderHome from "../components/_Home";

const Home = () => {
  return (
    <Layout>
      <RenderHome />
    </Layout>
  );
};

export default Home;
