import React from "react";
import AgoraRTM from "agora-rtm-sdk";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Footer from "../Reuseable/Footer";
import VideoPlayer from "components/Reuseable/VideoPlayer";
import { useAgoraContext } from "../../context/AgoraContext";
import { createAndPublishStream } from "../../lib/createAndPublishStream";
import { useToasterContext } from "context/ToasterContext";
import { checkIsArrayAndLength } from "utilities/checkIsArrayAndLength";
import { setItemToLocalStorage } from "utilities/setItemToLocalStorage";

const RenderRoom = () => {
  const navigate = useNavigate();

  const alert = useToasterContext();
  const {
    agoraEngine,
    agoraRTMInstance,
    agoraRTMChannel,
    //
    localVideoRef,
    remoteVideoRef,
    //
    localTracks,
    setLocalTracks,
    //
    members,
    localPeerDetails,
    setLocalPeerDetails,
    remotePeerDetails,
  } = useAgoraContext();

  const myUID = React.useMemo(() => localPeerDetails?.uid ?? "", [localPeerDetails?.uid]);
  const roomId = React.useMemo(() => localPeerDetails?.roomId ?? "", [localPeerDetails?.roomId]);
  const localUserType = React.useMemo(() => localPeerDetails?.userType ?? "", [localPeerDetails?.userType]);
  const localDisplayName = React.useMemo(() => localPeerDetails?.displayName ?? "", [localPeerDetails?.displayName]);

  React.useEffect(() => {
    if (!agoraEngine || !agoraRTMInstance || !agoraRTMChannel || !myUID || !roomId || !localUserType || !localDisplayName) {
      navigate(`/`);
      window.location.reload(true);
      return;
    }

    const startStream = async () => {
      await createAndPublishStream({
        agoraEngine,
        alert,
        cb: async (localTracks) => {
          /* Play our local video stream inside a html Video element */
          localTracks[1].play(localVideoRef.current);
          setLocalTracks(localTracks);

          setLocalPeerDetails((prev) => ({
            ...prev,
            isMicMuted: localTracks[0]?.muted,
            isVideoMuted: localTracks[1]?.muted,
          }));

          setItemToLocalStorage("isMyMicMuted", localTracks[0]?.muted);
          setItemToLocalStorage("isMyVideoMuted", localTracks[1]?.muted);

          /* Send our mic and video status to all the other peers when I join */
          agoraRTMChannel
            .sendMessage({
              text: JSON.stringify({ isMicMuted: localTracks[0]?.muted, isVideoMuted: localTracks[1]?.muted }),
              messageType: AgoraRTM.MessageType.TEXT,
            })
            .then(() =>
              console.log("Send mic and video status:", {
                isMicMuted: localTracks[0]?.muted,
                isVideoMuted: localTracks[1]?.muted,
              })
            )
            .catch((err) => console.log("Error sending mic and video status", err.message));
        },
      });
    };

    startStream();

    return () => Array.isArray(localTracks) && localTracks.length && localTracks.forEach((track) => track.close());
  }, [agoraEngine, agoraRTMInstance, agoraRTMChannel, myUID, roomId, localUserType, localDisplayName]);

  return (
    <Box sx={{ height: "100vh", width: "100vw", bgcolor: "#3E3E3E" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          padding: "2rem",
          overflow: "auto",
          height: "calc(100vh - 8rem)",

          "@media (max-width: 900px)": { flexDirection: "column" },
        }}
      >
        <VideoPlayer
          videoRef={localVideoRef}
          displayName={localDisplayName}
          videoTrack={checkIsArrayAndLength(localTracks) ? localTracks[1] : null}
          placeHolderImage={localUserType.toLowerCase() === "doctor" ? "/assets/icons/doctor.png" : "/assets/icons/patient.png"}
          placeHolderText={localUserType.toLowerCase() === "doctor" ? "Wating for Doctor ..." : "Wating for Patient ..."}
        />

        {members
          .filter((member) => member !== myUID)
          .map((member) => (
            <VideoPlayer
              key={member}
              videoRef={remoteVideoRef}
              videoTrack={{ muted: remotePeerDetails[member]?.isVideoMuted }}
              showDeviceStatus={true}
              displayName={remotePeerDetails[member]?.displayName}
              remotePeerMicMuted={remotePeerDetails[member]?.isMicMuted}
              remotePeerVideoMuted={remotePeerDetails[member]?.isVideoMuted}
              placeHolderImage={localUserType.toLowerCase() === "doctor" ? "/assets/icons/patient.png" : "/assets/icons/doctor.png"}
              placeHolderText={localUserType.toLowerCase() === "doctor" ? "Wating for Patient ..." : "Wating for Doctor ..."}
            />
          ))}
      </Box>

      <Footer />
    </Box>
  );
};

export default RenderRoom;
