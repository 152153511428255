import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import CustomButtonAdv from "../CustomButtonAdv";
import CustomDialog from "../CustomDialog";
import { useAgoraContext } from "context/AgoraContext";
import { useToasterContext } from "context/ToasterContext";
import { checkIsArrayAndLength } from "utilities/checkIsArrayAndLength";
import AgoraRTM from "agora-rtm-sdk";
import { setItemToLocalStorage } from "utilities/setItemToLocalStorage";
import { makeRequest } from "utilities/fetcher";
import { END_CONSULTATION } from "constants/urls";

const styles = {
  iconButton: { bgcolor: "#5C5C5C", padding: "1rem", "&:hover": { bgcolor: "#5C5C5C" } },
  icon: { color: "white", fontSize: "2.5rem" },
};

const Footer = () => {
  const navigate = useNavigate();

  const alert = useToasterContext();
  const { agoraEngine, agoraRTMInstance, agoraRTMChannel, localTracks, setLocalTracks, localPeerDetails, setLocalPeerDetails } = useAgoraContext();

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const toggleMic = (audioTrack) => async () => {
    if (!audioTrack) return alert("Failed to toggle mic", "warning");

    if (audioTrack.muted) {
      await audioTrack.setMuted(false);

      setLocalTracks((prev) => [audioTrack, prev[1]]);
      setLocalPeerDetails((prev) => ({ ...prev, isMicMuted: false }));

      setItemToLocalStorage("isMyMicMuted", false);

      agoraRTMChannel
        .sendMessage({
          text: JSON.stringify({ isMicMuted: false }),
          messageType: AgoraRTM.MessageType.TEXT,
        })
        .then(() => console.log("Mic muted", false))
        .catch((err) => console.log("Error sending mic status", err.message));
    } else {
      await audioTrack.setMuted(true);

      setLocalPeerDetails((prev) => ({ ...prev, isMicMuted: true }));
      setLocalTracks((prev) => [audioTrack, prev[1]]);

      setItemToLocalStorage("isMyMicMuted", true);

      agoraRTMChannel
        .sendMessage({
          text: JSON.stringify({ isMicMuted: true }),
          messageType: AgoraRTM.MessageType.TEXT,
        })
        .then(() => console.log("Mic muted", true))
        .catch((err) => console.log("Error sending mic status", err.message));
    }
  };

  const toggleCamera = (videoTrack) => async () => {
    if (!videoTrack) return alert("Failed to toggle camera", "warning");

    if (videoTrack.muted) {
      await videoTrack.setMuted(false);

      setLocalPeerDetails((prev) => ({ ...prev, isVideoMuted: false }));
      setLocalTracks((prev) => [prev[0], videoTrack]);

      setItemToLocalStorage("isMyVideoMuted", false);

      agoraRTMChannel
        .sendMessage({
          text: JSON.stringify({ isVideoMuted: false }),
          messageType: AgoraRTM.MessageType.TEXT,
        })
        .then(() => console.log("Video muted", false))
        .catch((err) => console.log("Error sending video status", err.message));
    } else {
      await videoTrack.setMuted(true);

      setLocalPeerDetails((prev) => ({ ...prev, isVideoMuted: true }));
      setLocalTracks((prev) => [prev[0], videoTrack]);

      setItemToLocalStorage("isMyVideoMuted", true);

      agoraRTMChannel
        .sendMessage({
          text: JSON.stringify({ isVideoMuted: true }),
          messageType: AgoraRTM.MessageType.TEXT,
        })
        .then(() => console.log("Video muted", true))
        .catch((err) => console.log("Error sending video status", err.message));
    }
  };

  const endCall = async () => {
    const data = await makeRequest({
      method: "POST",
      pathname: END_CONSULTATION,
      values: { roomId: localPeerDetails?.roomId, model: localPeerDetails?.userType },
    });

    let redirectURL = "/";

    if (typeof localPeerDetails?.userType === "string" && localPeerDetails?.userType?.toLowerCase() === "patient") {
      redirectURL = `${process.env.REACT_APP_PATIENT_UI_URL}/appointments?tab=2`;
    }

    if (typeof localPeerDetails?.userType === "string" && localPeerDetails?.userType?.toLowerCase() === "doctor") {
      redirectURL = `${process.env.REACT_APP_DOCTOR_UI_URL}/appointments?tab=1`;
    }

    if (data.code === 200) {
      Array.isArray(localTracks) && localTracks.length && localTracks.forEach((localTrack) => localTrack.close());

      agoraRTMChannel && (await agoraRTMChannel.leave());
      agoraRTMInstance && (await agoraRTMInstance.logout());
      agoraEngine && (await agoraEngine.leave());

      /* 
        Note that some browsers may not allow the window.close() method to be
        called if the tab was not opened by a script using the window.open() method

        window.opener will be null when we DONT specify noopener and noreferrer when
        opening the URL
      */
      if (window.opener === null && redirectURL === "/") {
        navigate(redirectURL);
        window.location.reload(true);
      } else if (window.opener === null) {
        navigate("/");
        window.open(redirectURL, "_blank", "noopener,noreferrer");
      } else {
        window.close();
      }
    } else alert("Failed to end consultation. Something went wrong.", "warning");
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "8rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={styles.iconButton}
          disabled={checkIsArrayAndLength(localTracks) ? false : true}
          onClick={toggleMic(checkIsArrayAndLength(localTracks) ? localTracks[0] : null)}
        >
          {checkIsArrayAndLength(localTracks) && localTracks[0]?.muted === true ? <MicOffIcon sx={styles.icon} /> : <MicIcon sx={styles.icon} />}
        </IconButton>

        <IconButton
          sx={styles.iconButton}
          disabled={checkIsArrayAndLength(localTracks) ? false : true}
          onClick={toggleCamera(checkIsArrayAndLength(localTracks) ? localTracks[1] : null)}
        >
          {checkIsArrayAndLength(localTracks) && localTracks[1]?.muted === true ? <VideocamOffIcon sx={styles.icon} /> : <VideocamIcon sx={styles.icon} />}
        </IconButton>

        <IconButton sx={styles.iconButton}>
          <MoreVertIcon sx={styles.icon} />
        </IconButton>

        <CustomButtonAdv
          text="End Call"
          enableIcon={false}
          fn={() => setShowCancelDialog(true)}
          rootStyles={{ bgcolor: "rgb(234,67,53)", "&:hover": { bgcolor: "rgb(234,67,53)" } }}
        />
      </Box>

      <CustomDialog
        openDialog={showCancelDialog}
        heading="End consultation"
        description="Are you sure about ending the consultation ?"
        btnText1="No"
        btnText2="Yes"
        handleDialogClose={() => setShowCancelDialog(false)}
        whenConfirmCB={endCall}
      />
    </>
  );
};

export default Footer;
